/*************************************************************
**************************************************************
	AccountManager stylesheet
**************************************************************
**************************************************************/
@media screen and (max-width: 767px) {
  wackadoo-administration button,
  wackadoo-event-broadcaster button
  {
    font-size: .7em !important;
    padding: 3px !important;
  }
}
